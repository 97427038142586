@import-normalize;

*,
*::after,
*::before {
  box-sizing: inherit;
  font-family: inherit;
}

html,
body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
}

html {
  background-image: linear-gradient(
    140deg,
    rgba(188, 213, 224, 0.15),
    rgba(255, 255, 255, 0.15) 100%
  );

  min-height: 100%;
}

body {
  line-height: 1.4;
  overflow-x: hidden;
  margin: 0 auto;

  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
address {
  font-style: normal;
}

a {
  color: inherit;
  text-decoration: inherit;
  font-size: inherit;
}

button {
  cursor: pointer;
}

#root {
  overflow: hidden;
}

.menu-open {
  transform: translateX(-15rem);
}

*:focus {
  outline: none;
}

:focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px #528deb, 0 0 0 4px #1b2e4d;
}

input.focus-visible {
  box-shadow: none;
}

.Toastify__toast--default {
  box-shadow: 0 16px 24px -16px rgba(59, 142, 184, 0.25) !important;
  color: #6c727e !important;
}

.Toastify__progress-bar--default {
  background: #1b2e4d !important;
}
